import resourceService from '../../services/resource.service'

export const state = {
    resources: [],
};

export const mutations = {

    CREATE_RESOURCE(state, value) {
        state.resources.push(value)
    },

    UPDATE_RESOURCE(state, value) {
        if(value) {
            let index = state.resources.findIndex(resource => resource.id == resource.id)
            state.resources[index] = value
        }
    },

    FIND_ALL_RESOURCES(state, value) {
        state.resources = value
    },

    DELETE_RESOURCE(state, value) {
        let index = state.resources.findIndex((res) => res.id == value)
        state.resources.splice(index, 1)
    },  
};

export const actions = {

    async createResource({commit}, payload) {
        try {
            let response = await resourceService.createResource(payload)
            commit('CREATE_RESOURCE', response[0])
            return response
        }
        catch(error) {
            //console.log('create task action', error)
        }
    },
    
    async updateResource({commit}, payload) {
        try {
            let response = await resourceService.updateResource(payload)
            commit('UPDATE_RESOURCE', response[0])
            return response
        }
        catch(error) {
            //console.log('update task action', error)
        }
    },

    async findAllResources({ commit }, payload) {
        try {
            const { businessId } = payload
            let response = await resourceService.findAllResources(businessId)
            commit('FIND_ALL_RESOURCES', response)
            //console.log('find all response', response)
            return response
        }
        catch(error) {
            console.log('find all resources action', error)
            throw error
        }
    },

    async deleteResource({commit}, payload) {
        try {
            const { userId, resourceId } = payload
            let response = await resourceService.deleteResource(userId, resourceId)
            commit('DELETE_RESOURCE', resourceId)
            return response
        }
        catch(error) {
            //console.log('delete resource action', error)
        }
    },

    async createSection({commit}, payload) {
        try {
            let response = await resourceService.createSection(payload)
            return response
        }
        catch(error) {
            //console.log('create task action', error)
        }
    },

    async updateSection({commit}, payload) {
        try {
            let response = await resourceService.updateSection(payload)
            return response
        }
        catch(error) {
            //console.log('create task action', error)
        }
    },

    async findAllSections({commit}, payload) {
        try {
            let response = await resourceService.findAllSections(payload)
            return response
        }
        catch(error) {
            //console.log('create task action', error)
        }
    },

    async deleteSection({commit}, payload) {
        try {
            let response = await resourceService.deleteSection(payload)
            return response
        }
        catch(error) {
            //console.log('create task action', error)
        }
    },

    setResources({commit}, payload) {
        commit('FIND_ALL_RESOURCES', payload)
    },
};
