import http from "../common/http";

class ResourceService {

  createResource(data) {
    return http.request('post', '/resource/create', data);
  }
  
  updateResource(data) {
    return http.request('put', '/resource/update', data);
  }

  findAllResources(businessId) {
    return http.request('get', `/resource/find-all?businessId=${businessId}`);
  }

  deleteResource(creatorId, resourceId) {
    return http.request('delete', `/resource/delete?creatorId=${creatorId}&resourceId=${resourceId}`);
  }

  createSection(data) {
    return http.request('post', '/resource/section/create', data);
  }

  updateSection(data) {
    return http.request('put', '/resource/section/update', data);
  }

  findAllSections(resourceId) {
    return http.request('get', `/resource/section/find-all?resourceId=${resourceId}`);
  }  

  deleteSection(sectionId) {
    return http.request('delete', `/resource/section/delete?sectionId=${sectionId}`);
  }
}

export default new ResourceService();
