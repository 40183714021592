import http from "../common/http";

class TaskService {

  createTask(data) {
    return http.request('post', '/task/create', data);
  }
  
  updateTask(data) {
    return http.request('put', '/task/update', data);
  }

  findAllTasks(userId, businessId) {
    return http.request('get', `/task/find-all?userId=${userId}&businessId=${businessId}`);
  }

  deleteTask(creatorId, taskId) {
    return http.request('delete', `/task/delete?creatorId=${creatorId}&taskId=${taskId}`);
  }

  likeTask(data) {
    return http.request('post', '/task/like', data);
  }

  createTaskComment(data) {
    return http.request('post', '/task/comment/create', data);
  }

  updateTaskComment(data) {
    return http.request('put', `/task/comment/update`, data);
  }

  findAllTaskComments(taskId) {
    return http.request('get', `/task/comment/find-all?taskId=${taskId}`);
  }

  deleteTaskComment(userId, taskId, commentId, isReply) {
    return http.request('delete', `/task/comment/delete?userId=${userId}&taskId=${taskId}&commentId=${commentId}&isReply=${isReply}`);
  }
}

export default new TaskService();
