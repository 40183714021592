import http from "../common/http";

class AuthService {
  login(data) {
    return http.request('post', '/auth/login', data);
  }
  
  register(data) {
    return http.request('post', '/auth/register', data);
  }

  findUserByEmail(email) {
    return http.request('get', `/auth/user?email=${email}`);
  }

  findUserById(userId) {
    return http.request('get', `/auth/user/id?userId=${userId}`);
  }

  verifyEmail(data) {
    return http.request('post', '/auth/verify', data);
  }

  resendVerificationCode(data) {
    return http.request('post', '/auth/verify/resend-code', data);
  }

  updateAvatar(data) {
    return http.request('post', `/auth/avatar`, data);
  }

  updateCover(data) {
    return http.request('post', `/auth/cover`, data);
  }

  updateUserDetails(data) {
    return http.request('post', `/auth/user/details`, data);
  }

  updateUsername(data) {
    return http.request('post', `/auth/user/username`, data);
  }

  findBalance(userId) {
    return http.request('get', `/auth/balance?userId=${userId}`);
  }

  changePassword(data) {
    return http.request('post', `/auth/change-password`, data);
  }

  checkIfPasswordExist(userId) {
    return http.request('get', `/auth/password-exist?userId=${userId}`);
  }

  setTheme(data) {
    return http.request('post', `/auth/theme`, data);
  }
}

export default new AuthService();