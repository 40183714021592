import http from "../common/http";

class CampaignService {

  createCampaign(data) {
    return http.request('post', '/campaign/create', data);
  }
  
  updateCampaign(data) {
    return http.request('put', '/campaign/update', data);
  }

  findAllCampaigns(userId, businessId) {
    return http.request('get', `/campaign/find-all?userId=${userId}&businessId=${businessId}`);
  }

  deleteCampaign(creatorId, campaignId) {
    return http.request('delete', `/campaign/delete?creatorId=${creatorId}&campaignId=${campaignId}`);
  }

  promoteCampaign(data) {
    return http.request('post', '/campaign/promote', data);
  }

  createCampaignComment(data) {
    return http.request('post', '/campaign/comment/create', data);
  }

  updateCampaignComment(data) {
    return http.request('put', `/campaign/comment/update`, data);
  }

  findAllCampaignComments(campaignId) {
    return http.request('get', `/campaign/comment/find-all?campaignId=${campaignId}`);
  }

  deletCampaignComment(userId, campaignId, commentId, isReply) {
    return http.request('delete', `/campaign/comment/delete?userId=${userId}&campaignId=${campaignId}&commentId=${commentId}&isReply=${isReply}`);
  }
}

export default new CampaignService();
