import http from "../common/http";

class BusinessService {
  create(data) {
    return http.request('post', '/business/create', data);
  }

  update(data) {
    return http.request('put', '/business/update', data);
  }

  findAll(userId, group, page, limit, search) {
    return http.request('get', `/business/find-all?userId=${userId}&group=${group}&page=${page}&limit=${limit}&search=${search}`);
  }

  findOne(id) {
    return http.request('get', `/business/find-one?id=${id}`);
  }

  subscribeToBusiness(data) {
    return http.request('post', '/business/subscribe', data);
  }

  searchBusinesses(search) {
    return http.request('get', `/business/search?search=${search}`);
  }

  delete(id) {
    return http.request('delete', `/business/delete?id=${id}`);
  }

  addProduct(data) {
    return http.request('post', '/business/product/add', data);
  }

  editProduct(data) {
    return http.request('put', '/business/product/edit', data);
  }

  deleteProduct(payload) {
    return http.request('post', `/business/product/delete`, payload);
  }

  findProduct(id) {
    return http.request('get', `/business/product/find-one?id=${id}`);
  }

  findOrdersByBusiness(id) {
    return http.request('get', `/business/orders?id=${id}`);
  }

  findAllTransactions(businessId) {
    return http.request('get', `/business/portfolio/transactions?businessId=${businessId}`);
  }

  createComment(data) {
    return http.request('post', '/business/comment/create', data);
  }

  updateComment(data) {
    return http.request('put', `/business/comment/update`, data);
  }

  findAllComments(businessId) {
    return http.request('get', `/business/comment/find-all?businessId=${businessId}`);
  }
  
  deleteComment(commentId) {
    return http.request('delete', `/business/comment/delete?commentId=${commentId}`);
  }
}

export default new BusinessService();