import http from "../common/http";


class UpdateService {
  createUpdate(data) {
    return http.request('post', '/update/create', data);
  }
  
  updateOne(data) {
    return http.request('put', '/update/one', data);
  }

  findAllUpdates(userId, businessId) {
    return http.request('get', `/update/find-all?userId=${userId}&businessId=${businessId}`);
  }

  deleteUpdate(creatorId, updateId) {
    return http.request('delete', `/update/delete?creatorId=${creatorId}&updateId=${updateId}`);
  }

  likeUpdate(data) {
    return http.request('post', '/update/like', data);
  }

  createComment(data) {
    return http.request('post', '/update/comment/create', data);
  }

  updateComment(data) {
    return http.request('put', `/update/comment/update`, data);
  }

  findAllComments(updateId) {
    return http.request('get', `/update/comment/find-all?updateId=${updateId}`);
  }

  deleteComment(userId, updateId, commentId, isReply) {
    return http.request('delete', `/update/comment/delete?userId=${userId}&updateId=${updateId}&commentId=${commentId}&isReply=${isReply}`);
  }
}

export default new UpdateService();
