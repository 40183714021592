import TaskService from '../../services/task.service'

export const state = {
    tasks: [],
    taskComments: [],
};

export const mutations = {

    CREATE_TASK(state, value) {
        state.tasks.push(value)
    },

    UPDATE_TASK(state, value) {
        if(value) {
            let index = state.tasks.findIndex(task => task.id == value.id)
            state.tasks[index] = value
        }
    },

    FIND_ALL_TASKS(state, value) {
        state.tasks = value
    },

    DELETE_TASK(state, value) {
        let index = state.tasks.findIndex((res) => res.id == value)
        state.tasks.splice(index, 1)
    },  

    LIKE_TASK(state, value) {
        let index = state.tasks.findIndex((res) => res.id == value.taskId)
        state.tasks[index].userHasLiked = value.userHasLiked
        if(value.userHasLiked) {
            state.tasks[index].totalLikes = state.tasks[index].totalLikes + 1 
        }
        else {
            state.tasks[index].totalLikes = state.tasks[index].totalLikes - 1
        }
    },

    ADD_TASK_COMMENT(state, payload) {
        if(payload.commentId) {
            let index = state.taskComments.findIndex(comment => comment.id == payload.commentId)
            state.taskComments[index].replies = payload.result.replies || []
        }
        else {
            if(!payload.result.replies) { 
                payload.result.replies = []
            }
           state.taskComments.unshift(payload.result) 
        }
        const taskIndex = state.tasks.findIndex(task => task.id == payload.taskId)
        state.tasks[taskIndex].totalComments = state.tasks[taskIndex].totalComments + 1
    },

    UPDATE_TASK_COMMENT(state, payload) {
        let index = state.taskComments.findIndex(comment => comment.id == payload.commentId)
        state.taskComments[index] = payload.result 
    },

    FIND_TASK_COMMENTS(state, value) {
        state.taskComments = value
    },

    DELETE_TASK_COMMENT(state, selectedCommentId, replyId, isReply) {
        if(isReply == false || isReply == 'false') {
            let index = state.taskComments.findIndex((res) => res.id == selectedCommentId)
            state.taskComments.splice(index, 1)
        }
        else {
            let index = state.taskComments.findIndex((res) => res.id == selectedCommentId)
            if(state.taskComments[index]) {
                if(state.taskComments[index].replies.length > 0) {
                    let replyIndex = state.taskComments[index].replies.findIndex((res) => res.id == replyId)
                    state.taskComments[index].replies.splice(replyIndex, 1) 
                }
            }
            
        }
    },
};

export const actions = {

    async createTask({commit}, payload) {
        try {
            let response = await TaskService.createTask(payload)
            commit('CREATE_TASK', response[0])
            return response
        }
        catch(error) {
            //console.log('create task action', error)
        }
    },
    
    async updateTask({commit}, payload) {
        try {
            let response = await TaskService.updateTask(payload)
            commit('UPDATE_TASK', response[0])
            return response
        }
        catch(error) {
            //console.log('update task action', error)
        }
    },

    async findAllTasks({ commit }, payload) {
        try {
            const { userId, businessId } = payload
            let response = await TaskService.findAllTasks(userId, businessId)
            commit('FIND_ALL_TASKS', response)
            //console.log('find all response', response)
            return response
        }
        catch(error) {
            console.log('find all tasks action', error)
            throw error
        }
    },

    async deleteTask({commit}, payload) {
        try {
            const { userId, taskId } = payload
            let response = await TaskService.deleteTask(userId, taskId)
            commit('DELETE_TASK', taskId)
            return response
        }
        catch(error) {
            //console.log('delete task action', error)
        }
    },

    setTasks({commit}, payload) {
        commit('FIND_ALL_TASKS', payload)
    },

    async likeTask({commit}, payload) {
        try {
            let response = await TaskService.likeTask(payload)
            //console.log('likeTask', response)
            const props = {
                taskId: payload.taskId,
                userHasLiked: response
            }
            commit('LIKE_TASK', props)
            return response
        }
        catch(error) {
            //console.log('update task action', error)
        }
    },

    async createTaskComment({commit}, payload) {
        try {
            let response = await TaskService.createTaskComment(payload)
            payload.result = response[0]
            commit('ADD_TASK_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('create task comment action', error)
        }
    },

    async updateTaskComment({commit}, payload) {
        try {
            let response = await TaskService.updateTaskComment(payload)
            //console.log('updateTaskComment', response)
            payload.result = response[0]
            commit('UPDATE_TASK_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('update task comment action', error)
        }
    },

    async findAllTaskComments({ commit }, taskId) {
        try {
            let response = await TaskService.findAllTaskComments(taskId)
            commit('FIND_TASK_COMMENTS', response)
            return response
        }
        catch(error) {
            //console.log('find all task comments action', error)
        }
    },

    async deleteTaskComment({commit}, payload) {
        try {
            const { userId, taskId, mainCommentId, commentId, isReply } = payload
            let response = await TaskService.deleteTaskComment(userId, taskId, commentId, isReply)
            commit('DELETE_TASK_COMMENT', mainCommentId, commentId, isReply)
            return response
        }
        catch(error) {
            console.log('delete task comment action', error)
        }
    },
};
