import campaignService from '../../services/campaign.service'

export const state = {
    campaigns: [],
    campaignComments: [],
};

export const mutations = {

    CREATE_CAMPAIGN(state, value) {
        state.campaigns.push(value)
    },

    UPDATE_CAMPAIGN(state, value) {
        if(value) {
            let index = state.campaigns.findIndex(campaign => campaign.id == value.id)
            state.campaigns[index] = value
        }
    },

    FIND_ALL_CAMPAIGNS(state, value) {
        state.campaigns = value
    },

    DELETE_CAMPAIGN(state, value) {
        let index = state.campaigns.findIndex((res) => res.id == value)
        state.campaigns.splice(index, 1)
    },

    PROMOTE_CAMPAIGN(state, value) {
        let index = state.campaigns.findIndex((res) => res.id == value.campaignId)
        state.campaigns[index].userHasPromoted = value.userHasPromoted
        if(value.userHasPromoted) {
            state.campaigns[index].totalPromotions = state.campaigns[index].totalPromotions + 1 
        }
        else {
            state.campaigns[index].totalPromotions = state.campaigns[index].totalPromotions - 1
        }
    },

    ADD_CAMPAIGN_COMMENT(state, payload) {
        if(payload.commentId) {
            let index = state.campaignComments.findIndex(comment => comment.id == payload.commentId)
            state.campaignComments[index].replies = payload.result.replies || []
        }
        else {
            if(!payload.result.replies) { 
                payload.result.replies = []
            }
           state.campaignComments.unshift(payload.result) 
        }
        const campaignIndex = state.campaigns.findIndex(campaign => campaign.id == payload.campaignId)
        state.campaigns[campaignIndex].totalComments = state.campaigns[campaignIndex].totalComments + 1
    },

    UPDATE_CAMPAIGN_COMMENT(state, payload) {
        let index = state.campaignComments.findIndex(comment => comment.id == payload.commentId)
        state.campaignComments[index] = payload.result 
    },

    FIND_CAMPAIGN_COMMENTS(state, value) {
        state.campaignComments = value
    },

    DELETE_CAMPAIGN_COMMENT(state, selectedCommentId, replyId, isReply) {
        if(isReply == false || isReply == 'false') {
            let index = state.campaignComments.findIndex((res) => res.id == selectedCommentId)
            state.campaignComments.splice(index, 1)
        }
        else {
            let index = state.campaignComments.findIndex((res) => res.id == selectedCommentId)
            if(state.campaignComments[index]) {
                if(state.campaignComments[index].replies.length > 0) {
                    let replyIndex = state.campaignComments[index].replies.findIndex((res) => res.id == replyId)
                    state.campaignComments[index].replies.splice(replyIndex, 1) 
                }
            }
            
        }
    },
};

export const actions = {

    async createCampaign({commit}, payload) {
        try {
            let response = await campaignService.createCampaign(payload)
            commit('CREATE_CAMPAIGN', response[0])
            return response
        }
        catch(error) {
            //console.log('create campaign action', error)
        }
    },
    

    async updateCampaign({commit}, payload) {
        try {
            let response = await campaignService.updateCampaign(payload)
            commit('UPDATE_CAMPAIGN', response[0])
            return response
        }
        catch(error) {
            //console.log('update campaign action', error)
        }
    },

    async findAllCampaigns({ commit }, payload) {
        try {
            const { userId, businessId } = payload
            let response = await campaignService.findAllCampaigns(userId, businessId)
            commit('FIND_ALL_CAMPAIGNS', response)
            //console.log('find all response', response)
            return response
        }
        catch(error) {
            console.log('find all campaigns action', error)
            throw error
        }
    },

    async deleteCampaign({commit}, payload) {
        try {
            const { userId, campaignId } = payload
            let response = await campaignService.deleteCampaign(userId, campaignId)
            commit('DELETE_CAMPAIGN', campaignId)
            return response
        }
        catch(error) {
            //console.log('delete campaign action', error)
        }
    },

    setCampaigns({commit}, payload) {
        commit('FIND_ALL_CAMPAIGNS', payload)
    },

    async promoteCampaign({commit}, payload) {
        try {
            let response = await campaignService.promoteCampaign(payload)
            //console.log('promoteCampaign', response)
            const props = {
                campaignId: payload.campaignId,
                userHasPromoted: response
            }
            commit('PROMOTE_CAMPAIGN', props)
            return response
        }
        catch(error) {
            //console.log('update campaign action', error)
        }
    },

    async createCampaignComment({commit}, payload) {
        try {
            let response = await campaignService.createCampaignComment(payload)
            payload.result = response[0]
            commit('ADD_CAMPAIGN_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('create campaign comment action', error)
        }
    },

    async updateCampaignComment({commit}, payload) {
        try {
            let response = await campaignService.updateCampaignComment(payload)
            //console.log('updateCampaignComment', response)
            payload.result = response[0]
            commit('UPDATE_CAMPAIGN_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('update campaign comment action', error)
        }
    },

    async findAllCampaignComments({ commit }, campaignId) {
        try {
            let response = await campaignService.findAllCampaignComments(campaignId)
            commit('FIND_CAMPAIGN_COMMENTS', response)
            return response
        }
        catch(error) {
            //console.log('find all campaign comments action', error)
        }
    },

    async deleteCampaignComment({commit}, payload) {
        try {
            const { userId, campaignId, mainCommentId, commentId, isReply } = payload
            let response = await campaignService.deleteCampaignComment(userId, campaignId, commentId, isReply)
            commit('DELETE_CAMPAIGN_COMMENT', mainCommentId, commentId, isReply)
            return response
        }
        catch(error) {
            console.log('delete campaign comment action', error)
        }
    },
};
