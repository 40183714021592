import updateService from '../../services/update.service'

export const state = {
    updates: [],
    updateComments: [],
};

export const mutations = {

    CREATE_UPDATE(state, value) {
        state.updates.push(value)
    },

    UPDATE_ONE(state, value) {
        if(value) {
            let index = state.updates.findIndex(update => update.id == value.id)
            state.updates[index] = value
        }
    },

    FIND_ALL_UPDATES(state, value) {
        state.updates = value
    },

    DELETE_UPDATE(state, value) {
        let index = state.updates.findIndex((res) => res.id == value)
        state.updates.splice(index, 1)
    },  

    LIKE_UPDATE(state, value) {
        let index = state.updates.findIndex((res) => res.id == value.updateId)
        state.updates[index].userHasLiked = value.userHasLiked
        
        if(value.userHasLiked) {
            state.updates[index].totalLikes = state.updates[index].totalLikes + 1 
        }
        else {
            state.updates[index].totalLikes = state.updates[index].totalLikes - 1
        }
    },

    ADD_COMMENT(state, payload) {
        if(payload.commentId) {
            let index = state.updateComments.findIndex(comment => comment.id == payload.commentId)
            state.updateComments[index].replies = payload.result.replies || []
        }
        else {
            if(!payload.result.replies) { 
                payload.result.replies = []
            }
           state.updateComments.unshift(payload.result) 
        }
        const updateIndex = state.updates.findIndex(update => update.id == payload.updateId)
        state.updates[updateIndex].totalComments = state.updates[updateIndex].totalComments + 1
    },

    UPDATE_COMMENT(state, payload) {
        let index = state.updateComments.findIndex(comment => comment.id == payload.commentId)
        state.updateComments[index] = payload.result 
    },

    FIND_COMMENTS(state, value) {
        state.updateComments = value
    },

    DELETE_COMMENT(state, selectedCommentId, replyId, isReply) {
        if(isReply == false || isReply == 'false') {
            let index = state.updateComments.findIndex((res) => res.id == selectedCommentId)
            state.updateComments.splice(index, 1)
        }
        else {
            let index = state.updateComments.findIndex((res) => res.id == selectedCommentId)
            if(state.updateComments[index]) {
                if(state.updateComments[index].replies.length > 0) {
                    let replyIndex = state.updateComments[index].replies.findIndex((res) => res.id == replyId)
                    state.updateComments[index].replies.splice(replyIndex, 1) 
                }
            }
            
        }
    },
};

export const actions = {

    async createUpdate({commit}, payload) {
        try {
            let response = await updateService.createUpdate(payload)
            commit('CREATE_UPDATE', response[0])
            return response
        }
        catch(error) {
            //console.log('create update action', error)
        }
    },
    
    async updateOne({commit}, payload) {
        try {
            let response = await updateService.updateOne(payload)
            commit('UPDATE_ONE', response[0])
            return response
        }
        catch(error) {
            //console.log('update update action', error)
        }
    },

    async findAllUpdates({ commit }, payload) {
        try {
            const { userId, businessId } = payload
            let response = await updateService.findAllUpdates(userId, businessId)
            commit('FIND_ALL_UPDATES', response)
            //console.log('find all response', response)
            return response
        }
        catch(error) {
            console.log('find all updates action', error)
            throw error
        }
    },

    async deleteUpdate({commit}, payload) {
        try {
            const { userId, updateId } = payload
            let response = await updateService.deleteUpdate(userId, updateId)
            commit('DELETE_UPDATE', updateId)
            return response
        }
        catch(error) {
            //console.log('delete update action', error)
        }
    },

    setUpdates({commit}, payload) {
        commit('FIND_ALL_UPDATES', payload)
    },

    async likeUpdate({commit}, payload) {
        try {
            let response = await updateService.likeUpdate(payload)
            const props = {
                updateId: payload.updateId,
                userHasLiked: response
            }
            commit('LIKE_UPDATE', props)
            return response
        }
        catch(error) {
            //console.log('like action', error)
        }
    },

    async createComment({commit}, payload) {
        try {
            let response = await updateService.createComment(payload)
            payload.result = response[0]
            commit('ADD_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('create update comment action', error)
        }
    },

    async updateComment({commit}, payload) {
        try {
            let response = await updateService.updateComment(payload)
            //console.log('updateComment', response)
            payload.result = response[0]
            commit('UPDATE_COMMENT', payload)
            return response
        }
        catch(error) {
            //console.log('update update comment action', error)
        }
    },

    async findAllComments({ commit }, updateId) {
        try {
            let response = await updateService.findAllComments(updateId)
            commit('FIND_COMMENTS', response)
            return response
        }
        catch(error) {
            //console.log('find all update comments action', error)
        }
    },

    async deleteComment({commit}, payload) {
        try {
            const { userId, updateId, mainCommentId, commentId, isReply } = payload
            let response = await updateService.deleteComment(userId, updateId, commentId, isReply)
            commit('DELETE_COMMENT', mainCommentId, commentId, isReply)
            return response
        }
        catch(error) {
            console.log('delete comment action', error)
        }
    },
};
